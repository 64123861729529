import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy, Edit as EditIcon, Play, Pause } from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "../../components/forms/select";
import Input from "../../components/forms/input";

import platforms from "../../assets/platforms.json";

import { mapDispatchToProps } from "../../utils";

const macroOptions = {
  audiomatic: {
    label: "Audiomatic",
    macros:
      // curly variables are replaced by adswizz
      // eslint-disable-next-line no-template-curly-in-string
      'lat={latitude}&lon={longitude}&uid={listenerid}&co={country}&ci={city}&re={region}&podcast_id=${parameters["aw_0_1st.podcastId"]}&showname=${showname}&episodename=${episodename}&aw_collection_id=${awCollectionId}',
  },
  mediamath: {
    label: "Mediamath",
    macros: "lat_long=[BID_ATTR.lat_long]&uid=[MOBILE_DEVICE_ID]&co=FR",
  },
  adotmob: {
    label: "Adotmob",
    macros: "lat={LAT}&lon={LON}&uid={IDFA}&co=FR",
  },
  zippycast: {
    label: "Zippycast",
    macros: "lat_long=[LATLONG]&co=FR",
  },
  dv360: { label: "DV360", macros: null },
};
const selectMacroOptions = Object.entries(macroOptions).map(([k, v]) => ({
  label: v.label,
  value: k,
}));

const typeOptions = {
  vast_4_0: { label: "VAST 4.2", value: "vast_4_2", query: "version=4" },
  vast_3_0: { label: "VAST 3.0", value: "vast_3_0", query: "version=3" },
  vast_2_0: { label: "VAST 2.0", value: "vast_2_0", query: "version=2" },
  daast: { label: "DAAST", value: "daast", query: "isDaast=true" },
};
const selectTypeOptions = Object.entries(typeOptions).map(([k, v]) => ({
  label: v.label,
  value: k,
}));

const mediaOptions = {
  mp3: { label: "mp3", value: "mp3" },
  ogg: { label: "ogg", value: "ogg" },
};
const selectMediaOptions = Object.entries(mediaOptions).map(([k, v]) => ({
  label: v.label,
  value: k,
}));

class Traffic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      media: null,
      macro: null,
      publisherLine: {},
      banner: {
        width: "",
        height: "",
      },
    };
  }
  componentWillMount() {
    const { actions, campaignId, adId } = this.props;
    actions.ad.get(adId);
    actions.campaign.get(campaignId);
    actions.creative.fetchAll(campaignId);
    actions.publisherLine.fetchAll(campaignId);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.ad.deleteCurrent();
    actions.campaign.deleteCurrent();
    actions.creative.deleteAll();
    actions.publisherLine.deleteAll();
  }
  getColorCard = campaign => {
    if (!campaign) return;
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "blue";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "green";
      return "yellow";
    }
    return "red";
  };

  onChangePublisherLine = publisherLineName => {
    const {
      publisherLines: { all: allPublisherLines },
    } = this.props;

    const publisherLine = allPublisherLines.find(publisherLine => publisherLine.name === publisherLineName);
    if (this.isCustomPlatform(publisherLine.platform)) {
      return this.setState({
        publisherLine,
        type: null,
        media: null,
        banner: {
          width: "",
          height: "",
        },
        macro: null,
      });
    }

    const { macro, type, media, banner } = this.findSettings(publisherLine.platform, publisherLine.publisher);
    this.setState({ publisherLine, type, media, banner, macro });
  };

  onChangeSize = size => {
    const [width, height] = size.split("x");
    this.setState({ banner: { width, height } });
  };

  findSettings = (platform, publisher) => {
    const { publishers, macro } = platforms.find(pfm => pfm.value === platform);
    const settings = publishers.find(pbr => pbr.value === publisher);
    const { type, media, banner } = settings;
    return { type, media, banner, macro };
  };
  onChange = (key, value) => {
    let state = { [key]: value };
    state.preset = "custom";
    this.setState(state);
  };

  onChangeState = type => () => {
    const { actions } = this.props;
    if (type === "campaign") {
      const {
        campaigns: { current },
      } = this.props;
      actions.campaign.update(current.id, { ...current, active: !current.active }).then(() => actions.campaign.get(current.id));
    } else if (type === "ad") {
      const {
        ads: { current: currentAd },
      } = this.props;
      this.props.actions.ad.update(currentAd.id, { ...currentAd, active: !currentAd.active }).then(() => actions.ad.get(currentAd.id));
    }
  };
  onCopy = () => {
    document.getElementById("copy").select();
    document.execCommand("copy");
    document.getElementById("url-copy-tooltip").style.opacity = 0;
    document.getElementById("url-copy-btn").addEventListener("mouseleave", this.resetTooltip.bind(this));
  };
  resetTooltip = () => {
    document.getElementById("url-copy-btn").removeEventListener("mouseleave", this.resetTooltip.bind(this));
    document.getElementById("url-copy-tooltip").style.removeProperty("opacity");
  };
  onClickHeaderCampaign = event => {
    event.stopPropagation();
    const { history, campaignId } = this.props;
    return history.push(`/campaign/${campaignId}`);
  };
  getPreviewUrl = () => {
    const {
      publisherLines: { all: allPublisherLines },
    } = this.props;
    if (this.props.creatives.all.length && this.props.ads.current) {
      const { current: currentAd } = this.props.ads;
      const creative = currentAd && this.props.creatives.all.find(crea => crea.adId === currentAd.id);
      const testPublisherLine = allPublisherLines && allPublisherLines.find(publisherLine => publisherLine.name === "test");
      if (!testPublisherLine) {
        return `${process.env.REACT_APP_SERVE_URL}/${creative.id}?${["pu=test", "so=test"].join("&")}`;
      }
      return testPublisherLine && `${process.env.REACT_APP_SERVE_URL}/v2/${creative.id}?publisherLineId=${testPublisherLine.id}`;
    }
  };
  getMappingUrl = () => {
    const { macro, type, media, publisherLine, banner } = this.state;
    const { current: currentAd } = this.props.ads;
    const creative = this.props.creatives.all.find(crea => crea.adId === currentAd.id);
    let query = [];
    const m = macroOptions[macro];
    if (!m) throw new Error(`macro format for ${macro} doesn't exist`);
    if (m.macros) query.push(m.macros);

    const t = typeOptions[type];
    if (!t) throw new Error(`type ${type} doesn't exist`);
    if (t.query) query.push(t.query);

    const med = mediaOptions[media];
    if (!med) throw new Error(`Media ${media} doesn't exist`);
    if (med) query.push(`media=${med.value}`);

    return `${process.env.REACT_APP_SERVE_URL}/v2/${creative.id}?${query.join(
      "&",
    )}&publisherLineId=${publisherLine.id}&width=${banner.width}&height=${banner.height}`;
  };

  hasValidBannerSize(platform, publisher) {
    const { current: currentAd } = this.props.ads;
    const creative = currentAd && this.props.creatives.all.find(crea => crea.adId === currentAd.id);
    const sizes =
      (creative &&
        creative.companionBanner.banners.map(banner => ({
          width: banner.width,
          height: banner.height,
        }))) ||
      [];
    const { banner } = this.findSettings(platform, publisher);

    return sizes.some(size => JSON.stringify(size) === JSON.stringify(banner));
  }

  isCustomPlatform = currentPlatform => !platforms.some(platform => platform.value === currentPlatform);

  render() {
    const { macro, media, type, publisherLine, banner } = this.state;
    const {
      campaigns: { current: currentCampaign },
      ads: { current: currentAd },
      publisherLines: { all: allPublisherLines },
    } = this.props;
    const isCustomPlatform = publisherLine.platform ? this.isCustomPlatform(publisherLine.platform) : false;
    const url =
      banner && banner.width && banner.height && macro && type && media && publisherLine && publisherLine.id && this.getMappingUrl();
    const creative = currentAd && this.props.creatives.all.find(crea => crea.adId === currentAd.id);
    const previewUrl = creative && this.getPreviewUrl();
    const sizes =
      (creative &&
        creative.companionBanner.banners.map(banner => ({
          width: banner.width,
          height: banner.height,
        }))) ||
      [];

    const publisherLinesOptions = allPublisherLines.map(({ name, platform, publisher }) => {
      if (this.isCustomPlatform(platform)) {
        return {
          label: `${name} - ${platform} | ${publisher}`,
          value: name,
          disabled: false,
        };
      }
      if (this.hasValidBannerSize(platform, publisher))
        return {
          label: `${name} - ${platform} | ${publisher}`,
          value: name,
          disabled: false,
        };
      const { banner } = this.findSettings(platform, publisher);
      return {
        label: `${name} - ${platform} | ${publisher} (Banner ${banner.width}x${banner.height} not available)`,
        value: name,
        disabled: true,
      };
    });

    if (this.props.ads.isGetting) return <p>{"Loader"}</p>;
    return (
      <div className="my-9 my-md-9 my-lg-9 traffic">
        <div className="container">
          <div className="page-header">
            <div className="card">
              <div className={`card-status bg-${this.getColorCard(currentCampaign)}`} />
              <div className="card-header">
                <h1 className="mb-3 mt-3" style={{ cursor: "pointer" }} onClick={this.onClickHeaderCampaign}>
                  {currentCampaign && currentCampaign.name}
                </h1>
                <div className="card-options">
                  {currentCampaign && currentCampaign.active ? (
                    <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState("campaign")} />
                  ) : (
                    <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState("campaign")} />
                  )}
                  <Link onClick={e => e.stopPropagation()} to={`/campaign/${currentCampaign && currentCampaign.id}/edit`} className="mt-1">
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card">
              <div className={`card-status bg-${this.getColorCard(currentAd)}`} />
              <div className="card-header">
                <h2 className="mb-3 mt-3">{currentAd && currentAd.name}</h2>
                <div className="card-options">
                  {currentAd && currentAd.active ? (
                    <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState("ad")} />
                  ) : (
                    <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState("ad")} />
                  )}
                  <Link
                    onClick={e => e.stopPropagation()}
                    to={`/campaign/${currentCampaign && currentCampaign.id}/ads/${currentAd && currentAd.id}/edit`}
                    className="mt-1"
                  >
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <h2 className="mb-5">{"Traffic"}</h2>
          {!creative ? (
            <h4>You need to create a creative first</h4>
          ) : (
            <>
              {previewUrl && (
                <>
                  <h4 className="mb-2">{"Preview"}</h4>
                  <div className={`d-flex mb-5`}>
                    <a target="_blank" className="preview-link" href={previewUrl}>
                      {previewUrl}
                    </a>
                  </div>
                </>
              )}
              {publisherLinesOptions.length > 0 && (
                <>
                  <h4 className="mb-2">{"Publisher Line"}</h4>
                  <div className={`d-flex mb-5 select-container`}>
                    <Select
                      options={publisherLinesOptions}
                      onChange={this.onChangePublisherLine}
                      value={publisherLine.name}
                      placeholder="Select a publisher line..."
                    />
                  </div>
                </>
              )}
              {isCustomPlatform && (
                <>
                  <h4 className="mb-2">{"Banner size"}</h4>
                  <div className={`d-flex mb-5 select-container`}>
                    <Select
                      options={sizes.map(({ width, height }) => ({
                        label: `${width}x${height}`,
                        value: `${width}x${height}`,
                      }))}
                      onChange={this.onChangeSize}
                      value={banner && banner.width && banner.height && `${banner.width}x${banner.height}`}
                      placeholder="Select a banner size ..."
                    />
                  </div>
                  <h4 className="mb-2">Settings</h4>
                  <div className="d-flex mb-5 select-container">
                    <Select
                      label="Macro format"
                      options={selectMacroOptions}
                      onChange={value => this.onChange("macro", value)}
                      value={macro}
                      placeholder="Select a macro format"
                    />
                    <Select
                      label="Type"
                      options={selectTypeOptions}
                      onChange={value => this.onChange("type", value)}
                      value={type}
                      placeholder="Select a type"
                    />
                    <Select
                      label="Media format"
                      options={selectMediaOptions}
                      onChange={value => this.onChange("media", value)}
                      value={media}
                      placeholder="Select a media format"
                    />
                  </div>
                </>
              )}

              {url && (
                <>
                  <h4 className="mb-2">Url</h4>
                  <div className="url-container">
                    <Input isDisabled={true} value={url} />
                    <div className="copy-btn">
                      <div id="url-copy-tooltip" className="tooltip">
                        Copy
                      </div>
                      <Copy id="url-copy-btn" style={{ cursor: "pointer" }} onClick={this.onCopy} />
                    </div>
                  </div>
                  <textarea id="copy" value={url} readOnly />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ creatives, ads, campaigns, publisherLines }) => ({
    creatives,
    ads,
    campaigns,
    publisherLines,
  }),
  mapDispatchToProps,
)(Traffic);
